import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2OdometerModule } from 'ng2-odometer';
import { LocationStrategy, PathLocationStrategy, HashLocationStrategy } from '@angular/common';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgwWowModule } from 'ngx-wow';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { IconsModule } from './icons/icons.module';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';
import { FunfactComponent } from './funfact/funfact.component';
import { WorksComponent } from './home/works/works.component';
import { FeedbackComponent } from './home/feedback/feedback.component';
import { PartnerComponent } from './partner/partner.component';
import { AboutComponent } from './about/about.component';

import { HomeTeamComponent } from './home/home-team/home-team.component';
import { ContactComponent } from './contact/contact.component';
import { CareersComponent } from './careers/careers.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RepairComponent } from './repair/repair.component';
import { RepairPartnerComponent } from './repair/repair-partner/repair-partner.component';
import { HeaderStyleTwoComponent } from './header-style-two/header-style-two.component';
import { HeaderStyleThreeComponent } from './header-style-three/header-style-three.component';
import { ServicesDetailsTurnkeyProjectsComponent } from './services-details-turnkey-projects/services-details-turnkey-projects.component';
import { ServicesDetailsEducationalTrainersComponent } from './services-details-educational-trainers/services-details-educational-trainers.component';
import { ServicesDetails3dPrintingAndScanningComponent } from './services-details-3d-printing-and-scanning/services-details-3d-printing-and-scanning.component';
import { ServicesDetailsEngineeringEquipmentComponent } from './services-details-engineering-equipment/services-details-engineering-equipment.component';
import { ServicesDetailsRawMaterialsComponent } from './services-details-raw-materials/services-details-raw-materials.component';
import { ServicesDetailsLaboratoryEquipmentComponent } from './services-details-laboratory-equipment/services-details-laboratory-equipment.component';
import { ServicesDetailsCompostingSolutionsComponent } from './services-details-composting-solutions/services-details-composting-solutions.component';
import { ServicesDetailsEBikesComponent } from './services-details-e-bikes/services-details-e-bikes.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    TeamComponent,
    FunfactComponent,
    WorksComponent,
    FeedbackComponent,
    PartnerComponent,
    AboutComponent,
    ServicesDetailsTurnkeyProjectsComponent,
    ServicesDetailsEducationalTrainersComponent,
    ServicesDetails3dPrintingAndScanningComponent,
    ServicesDetailsEngineeringEquipmentComponent,
    ServicesDetailsRawMaterialsComponent,
    ServicesDetailsLaboratoryEquipmentComponent,
    ServicesDetailsCompostingSolutionsComponent,
    ServicesDetailsEBikesComponent,
    HomeTeamComponent,
    ContactComponent,
    CareersComponent,
    ComingSoonComponent,
    NotFoundComponent,
    RepairComponent,
    RepairPartnerComponent,
    HeaderStyleTwoComponent,
    HeaderStyleThreeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsModule,
    BrowserAnimationsModule,
    CarouselModule,
    Ng2OdometerModule.forRoot(),
    SlickCarouselModule, NgwWowModule
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  // providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
