import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-team-home",
  templateUrl: "./home-team.component.html",
  styleUrls: ["./home-team.component.css"],
})
export class HomeTeamComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  teamMembers = [
    {
      name: "Naomal Dias",
      designation: "Managing Director / Founder",
      imageUrl: "naomal-dias.jfif",
      linkedinUrl: "https://www.linkedin.com/in/naomal-dias-0239a9188/",
    },
    {
      name: "Devini Dias",
      designation: "Director",
      imageUrl: "devini-dias.jfif",
      linkedinUrl: "https://www.linkedin.com/in/devini-dias-a489a93b/",
    },
    {
      name: "Achila Liyanage",
      designation: "Manager – Finance",
      imageUrl: "achila-liyanage.jfif",
      linkedinUrl: "https://www.linkedin.com/in/achila-liyanage-0333a81ab/",
    },
    {
      name: "Sachith Fernando ",
      designation:
        "Head - 3D Printing Services / Senior Engineer - Mechanical Engineering",
      imageUrl: "sachith-fernando.jfif",
      linkedinUrl: "https://www.linkedin.com/in/sachith-fernando-40304a10a/",
    },
    {
      name: "Nithesh Balasunthararaja",
      designation: "Senior Engineer - Mechanical Engineering",
      imageUrl: "nithesh-balasunthararaja.jfif",
      linkedinUrl:
        "https://www.linkedin.com/in/nithesh-balasunthararaja-ab1616101/",
    },

    {
      name: "Sakuni Parakumge",
      designation: "Senior Executive – Operations",
      imageUrl: "sakuni-parakumge.jfif",
      linkedinUrl: "https://www.linkedin.com/in/sakuni-parakumge-0b329878/",
    },
    {
      name: "Upali Gunawardena",
      designation: "Consultant – Raw Materials.",
      imageUrl: "male.jpg",
      linkedinUrl: "#",
    },
    {
      name: "Sampath Weragoda",
      designation: "Consultant – Materials Engineering",
      imageUrl: "sampath-weragoda.jfif",
      linkedinUrl: "https://www.linkedin.com/in/sampath-weragoda-8923697b/",
    },
    {
      name: "Nimal Jayantha",
      designation:
        "Consultant – Energy, Agriculture and Water Related projects",
      imageUrl: "male.jpg",
      linkedinUrl: "#",
    },
    {
      name: "Nuwan Kodikara ",
      designation: "Consultant – Electrical Engineering ",
      imageUrl: "male.jpg",
      linkedinUrl: "https://www.linkedin.com/in/isurukodikara/",
    },
    {
      name: "Damitha Nanayakkara",
      designation: "Consultant – Civil Engineering",
      imageUrl: "male.jpg",
      linkedinUrl: "https://www.linkedin.com/in/damitha-nanayakkara-a29275129/",
    },
    {
      name: "Isuru Karunarathne",
      designation: "Consultant – Mechanical and Materials Engineering",
      imageUrl: "isuru-karunaratne.jfif",
      linkedinUrl: "https://www.linkedin.com/in/isurukarunaratne/",
    },
    {
      name: "Jamintha Perera",
      designation: "Technician",
      imageUrl: "jamintha-perera.jpg",
      linkedinUrl: "#",
    },
    {
      name: "Mahinda Jayarathna",
      designation: "Administration Assistant",
      imageUrl: "mahinda-jayarathne.jpg",
      linkedinUrl: "#",
    },
  ];

  customOptions: OwlOptions = {
    loop: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    autoplayTimeout: 1000,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 4,
      },
      1500: {
        items: 5,
      },
    },
  };
}
