import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  partnerLogos = [
    {
      name: "Adam Equipment",
      url: "https://www.adamequipment.asia"
    },
    {
      name: "ARRB Systems",
      url: "https://arrbsystems.com/"
    },
    {
      name: "Brendle",
      url: "https://www.brendle.es/welcome"
    },
    {
      name: "Camlab",
      url: "https://www.camlab.co.uk/"
    },
    {
      name: "Cla-val",
      url: "https://www.cla-val.com/"
    },
    {
      name: "Genlab",
      url: "https://genlab.co.uk"
    },
    {
      name: "Gladstone Engineering",
      url: "https://www.gladstoneengineering.com/"
    },
    {
      name: "Imerys",
      url: "https://www.imerys.com/"
    },
    {
      name: "Ropczyce.sa",
      url: "https://ropczyce.com.pl/?lang=en"
    },
    
    {
      name: "Stuermer Machines",
      url: "https://www.stuermer-machines.com"
    }
  ];


}
