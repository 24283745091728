import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare let $: any;

@Component({
  selector: 'app-services-details-3d-printing-and-scanning',
  templateUrl: './services-details-3d-printing-and-scanning.component.html',
  styleUrls: ['./services-details-3d-printing-and-scanning.component.css']
})
export class ServicesDetails3dPrintingAndScanningComponent implements OnInit {

  public safeVideoURL1;
  public safeVideoURL2;
  public safeVideoURL3;
  public safeVideoURL4;
  public safeVideoURL5;

  public urlLoaded = false

  constructor(private _sanitizer: DomSanitizer) {
    let videoURL1 = "https://www.youtube.com/embed/MhofEeMk2U4";
    this.safeVideoURL1 = this._sanitizer.bypassSecurityTrustResourceUrl(videoURL1);

    let videoURL2 = "https://www.youtube.com/embed/1mqYWd5owMI";
    this.safeVideoURL2 = this._sanitizer.bypassSecurityTrustResourceUrl(videoURL2);

    let videoURL3 = "https://www.youtube.com/embed/mLQypQw4PRs";
    this.safeVideoURL3 = this._sanitizer.bypassSecurityTrustResourceUrl(videoURL3);

    let videoURL4 = "https://www.youtube.com/embed/DiWDGRCYyW0";
    this.safeVideoURL4 = this._sanitizer.bypassSecurityTrustResourceUrl(videoURL4);

    let videoURL5 = "https://www.youtube.com/embed/l_s_aVlcRHo";
    this.safeVideoURL5 = this._sanitizer.bypassSecurityTrustResourceUrl(videoURL5);

    this.urlLoaded = true;

  }

  ngOnInit() {
    // FAQ Accordion
    $(function () {
      $('.accordion').find('.accordion-title').on('click', function () {
        // Adds Active Class
        $(this).toggleClass('active');
        // Expand or Collapse This Panel
        $(this).next().slideToggle('fast');
        // Hide The Other Panels
        $('.accordion-content').not($(this).next()).slideUp('fast');
        // Removes Active Class From Other Titles
        $('.accordion-title').not($(this)).removeClass('active');
      });
    });
  }

}
