import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { TeamComponent } from './team/team.component';
import { ContactComponent } from './contact/contact.component';
import { CareersComponent } from './careers/careers.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RepairComponent } from './repair/repair.component';
import { ServicesDetailsTurnkeyProjectsComponent } from './services-details-turnkey-projects/services-details-turnkey-projects.component';
import { ServicesDetailsEducationalTrainersComponent } from './services-details-educational-trainers/services-details-educational-trainers.component';
import { ServicesDetails3dPrintingAndScanningComponent } from './services-details-3d-printing-and-scanning/services-details-3d-printing-and-scanning.component';
import { ServicesDetailsEngineeringEquipmentComponent } from './services-details-engineering-equipment/services-details-engineering-equipment.component';
import { ServicesDetailsRawMaterialsComponent } from './services-details-raw-materials/services-details-raw-materials.component';
import { ServicesDetailsLaboratoryEquipmentComponent } from './services-details-laboratory-equipment/services-details-laboratory-equipment.component';
import { ServicesDetailsCompostingSolutionsComponent } from './services-details-composting-solutions/services-details-composting-solutions.component';
import { ServicesDetailsEBikesComponent } from './services-details-e-bikes/services-details-e-bikes.component';


const routes: Routes = [
  // { path: '', redirectTo: '/home', pathMatch: 'full' }, // for defaault redirecting
  { path: '', component: RepairComponent },
  { path: 'home', component: RepairComponent },
  { path: 'repair', component: RepairComponent },
  { path: 'about', component: AboutComponent },
  { path: 'services-details-turnkey-projects', component: ServicesDetailsTurnkeyProjectsComponent },
  { path: 'services-details-educational-equipment', component: ServicesDetailsEducationalTrainersComponent },
  { path: 'services-details-3d-printing-and-scanning', component: ServicesDetails3dPrintingAndScanningComponent },
  { path: 'services-details-engineering-equipment', component: ServicesDetailsEngineeringEquipmentComponent },
  { path: 'services-details-raw-materials', component: ServicesDetailsRawMaterialsComponent },
  { path: 'services-details-laboratory-equipment', component: ServicesDetailsLaboratoryEquipmentComponent },
  { path: 'services-details-composting-solutions', component: ServicesDetailsCompostingSolutionsComponent },
  { path: 'services-details-e-bikes', component: ServicesDetailsEBikesComponent},
  

  
  { path: 'team', component: TeamComponent },

  { path: 'contact', component: ContactComponent },
  { path: 'careers', component: CareersComponent },


  { path: 'coming-soon', component: ComingSoonComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
